import {
  isRef,
  onBeforeUnmount,
  onMounted,
  ref,
  unref,
  watch,
} from 'vue';
import {
  differenceInMilliseconds,
  differenceInSeconds,
  isAfter,
} from 'date-fns';
import useRealtime from './realtime.js';

export default function useTimer(targetRefOrValue) {
  const now = ref(new Date());
  let target = unref(targetRefOrValue);

  const { serverTime, latency } = useRealtime();

  let timer = null;

  const setupTimer = () => {
    if (!isAfter(now.value, target)) {
      setTimeout(() => {
        timer = setInterval(() => {
          const n = serverTime(new Date());
          const diff = differenceInSeconds(target, n);
          if (
            // wait for first ticks so we have an inkling of a delta
            latency.value !== null && (diff < 60 || n.getMinutes() !== now.value.getMinutes())
          ) {
            now.value = n;
          }

          if (isAfter(now.value, target)) {
            clearInterval(timer);
            timer = null;
          }
        }, 100);
      }, (1000 - new Date().getMilliseconds()) % 100);
    }
  };

  const clearTimer = () => {
    if (timer) {
      clearInterval(timer);
      timer = null;
    }
  };

  onMounted(() => {
    setupTimer();
  });

  onBeforeUnmount(() => {
    clearTimer();
  });

  if (isRef(targetRefOrValue)) {
    watch(targetRefOrValue, (nt) => {
      clearTimer();
      target = nt;
      setupTimer();
    });
  }

  return now;
}

export function useSince(sinceRef, interval = 500) {
  let timer = null;
  const sinceMillis = ref(null);

  const setupTimer = () => {
    timer = setInterval(() => {
      if (sinceRef.value) {
        sinceMillis.value = differenceInMilliseconds(new Date(), sinceRef.value);
      } else {
        sinceMillis.value = null;
      }
    }, interval);
  };

  const clearTimer = () => {
    if (timer) {
      clearInterval(timer);
      timer = null;
    }
  };

  onMounted(() => {
    setupTimer();
  });

  onBeforeUnmount(() => {
    clearTimer();
  });

  return sinceMillis;
}
