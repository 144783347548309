<template>
  <thead
      v-if="$slots.header"
      :class="{
        ...headerClass,
        'table-content__header--sticky': sticky,
      }"
      class="table-content__header">
  <slot
      name="header"
      :rows="rows" />
  </thead>

  <tbody
      class="table-content__body"
      :class="bodyClass">
  <template v-if="rows && rows.length === 0">
    <tr v-if="columns !== null">
      <td :colspan="columns">
        <slot
            name="empty" />
      </td>
    </tr>
    <slot
        v-else
        name="empty" />
  </template>
  <template v-else-if="(rows && rows.length > 0) || $slots.default">
    <transition-group
        v-if="rowAnimated"
        :name="rowAnimation">
      <template
          v-for="(row, index) in rows"
          :key="row[idField]">
        <slot
            name="row"
            :index="index"
            :row="row" />
      </template>
    </transition-group>
    <template
        v-for="(row, index) in rows"
        v-else
        :key="row[idField]">
      <slot
          name="row"
          :index="index"
          :row="row" />
    </template>
    <slot />
  </template>
  </tbody>

  <tfoot
      v-if="$slots.footer"
      :class="footerClass"
      class="table-content__footer">
  <slot name="footer" />
  </tfoot>
</template>
<script>
export default {
  props: {
    headerClass: {
      type: Object,
      default: () => ({}),
    },
    bodyClass: {
      type: [String, Array, Object],
      default: () => null,
    },
    footerClass: {
      type: [String, Array, Object],
      default: () => null,
    },
    rows: {
      type: Array,
      default: () => null,
    },
    columns: {
      type: Number,
      default: () => null,
    },
    idField: {
      type: String,
      default: () => 'id',
    },
    rowAnimation: {
      type: String,
      default: () => 'animation-cross-fade',
    },
    rowAnimated: {
      type: Boolean,
      default: () => true,
    },
    sticky: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style>

/*noinspection CssUnusedSymbol*/
.table-content {
  --sticky-offset: 0;

  &__header {
    --table-current-row-background: var(--color-default-background);
    background-color: var(--color-default-background);
    border-bottom: var(--dimension-line) solid var(--color-primary-background);

    &--sticky {
      position: sticky;
      top: var(--sticky-offset);
      z-index: var(--layer-menu);

      background-color: var(--color-background);
    }
  }

  &__footer {
    background-color: var(--color-background);
    border-top: var(--dimension-line) solid var(--color-primary-background);
  }

  &__body {
    border-bottom: var(--dimension-line) solid var(--color-primary-background);
  }

  &__body + &__body {
    border-top: var(--dimension-medium) solid var(--color-default-background);
  }

  &__body + &__header {
    border-top: var(--dimension-line) solid var(--color-background);
  }

}
</style>
