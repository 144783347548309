<template>
  <DropContainer
      ref="root"
      :allowed="allowed"
      :context="context"
      :resolver="resolver"
      @drag-leave="handleDragLeave"
      @drop="handleDrop"
      @drag-over="handleDragOver">
    <template #default="{allowDrop}">
      <slot
          :items="orderedItems"
          :allow-drop="allowDrop" />
    </template>
  </DropContainer>
</template>
<script>
import {
  computed,
  onBeforeUnmount,
  ref,
  toRefs,
} from 'vue';
import DropContainer from './DropContainer.vue';

export default {
  components: { DropContainer },
  props: {
    ...DropContainer.props,
    items: {
      type: Array,
      required: true,
    },
    contentType: {
      type: String,
      required: true,
    },
  },
  emits: [...DropContainer.emits, 'update:items'],
  setup(props, { emit }) {
    const { items, contentType, context } = toRefs(props);
    const placeholder = ref(null);
    const orderedItems = computed(() => {
      if (placeholder.value !== null) {
        // get the placeholder data
        const { index, id, item } = placeholder.value;
        const localItem = item || items.value.find(
          (i) => i.id === id || context.value.item.value?.id === i.id,
        ) || {};
        const contextItems = context.value.items.value;
        const localItems = (contextItems?.length > 0) ? contextItems : [localItem];

        const ordered = items.value.slice().filter((i) => i.id !== id
            && context.value.item.value?.id !== i.id
            && !localItems.some((ci) => ci.id === i.id));
        ordered.splice(index, 0, ...localItems.map((ci) => ({ ...ci, placeholder: true })));
        return ordered;
      }
      return items.value || [];

    });
    const root = ref(null);

    const findDraggableContainer = (e) => {
      if (!e || e === root.value?.$el) {
        return null;
      }
      if (e.dataset?.draggableContentType === contentType.value && !!e.dataset.draggableContentId) {
        return e;
      }
      return findDraggableContainer(e.parentNode);
    };

    const dropHandle = (item, source) => {
      if (item && source !== root.value?.$el) {
        // We are not the source, so we need to remove this
        if (context.value?.items.value.length) {
          const dropped = context.value.items.value;
          const ordered = items.value.slice().filter((i) => !dropped.some((d) => d.id === i.id));
          emit('update:items', ordered);
        } else {
          const ordered = items.value.slice().filter((i) => i.id !== item.id);
          emit('update:items', ordered);
        }
      }
    };
    context.value.on('drop', dropHandle);

    onBeforeUnmount(() => context.value.off('drop', dropHandle));

    return {
      root,
      orderedItems,

      handleDrop(item, ev) {
        // If we have no placeholder, we assume we add to the end.
        // This happens for example when you drop in empty space.
        const { index } = (placeholder.value !== null)
          ? placeholder.value : { index: items.value.length };

        if (context.value?.items.value.length) {
          const dropped = context.value.items.value;
          const ordered = items.value.slice().filter((i) => !dropped.some((d) => d.id === i.id));
          ordered.splice(index, 0, ...dropped);

          emit('update:items', ordered);

        } else {
          const ordered = items.value.slice().filter((i) => i.id !== item.id);
          ordered.splice(index, 0, item);

          emit('update:items', ordered);
        }

        placeholder.value = null;

        emit('drop', item, index, ev);
      },

      handleDragLeave(ev) {
        if (placeholder.value !== null) {
          placeholder.value = null;
        }
        emit('drag-leave', ev);
      },

      handleDragOver(item, ev) {
        const container = findDraggableContainer(ev.target);

        if (items.value.length === 0 && !placeholder.value) {
          placeholder.value = { index: 0, item, contentType };
        } else if (container) {
          const topAnchored = [...container.parentNode.querySelectorAll("[data-draggable-anchored='top']")].length;
          const bottomAnchored = [...container.parentNode.querySelectorAll("[data-draggable-anchored='bottom']")].length;
          const dragging = context.value.items.value.length || 1;
          const index = orderedItems.value.findIndex(
            (i) => i.id === container.dataset.draggableContentId,
          );
          const bounds = container.getBoundingClientRect();
          let placeholderIndex = null;
          const offset = context.value.offset.value || 0;
          if ((ev.clientY - offset) < (bounds.y + (bounds.height / 2))) {
            placeholderIndex = index;
          } else {
            placeholderIndex = index + 1;
          }
          const minIndex = topAnchored;
          if (placeholderIndex < minIndex) {
            placeholderIndex = minIndex;
          }
          const maxIndex = orderedItems.value.length - bottomAnchored - dragging;
          if (placeholderIndex > maxIndex) {
            placeholderIndex = maxIndex;
          }

          if (placeholder.value?.index !== placeholderIndex && placeholderIndex !== null) {
            placeholder.value = { index: placeholderIndex, item, contentType };
          }
        }

        emit('drag-over', ev);
      },
    };
  },
};
</script>
