<template>
  <div
      ref="root"
      class="drop-container"
      :class="{
        'drop-container--drop': allowDrop === true,
        'drop-container--no-drop': allowDrop === false,
      }"
      @drop="handleDrop"
      @dragleave="handleDragLeave"
      @dragover="handleDragOver">
    <slot :allow-drop="allowDrop" />
  </div>
</template>
<script>

import {
  ref,
  toRefs,
} from 'vue';
import DropContext from './context.js';

export default {
  props: {
    allowed: {
      type: Function,
      default: () => () => true,
    },
    resolver: {
      type: Function,
      default: (a) => a,
    },
    context: {
      type: Object,
      default: () => new DropContext(),
    },
  },

  emits: ['drag-over', 'drag-leave', 'drop'],
  setup(props, { emit }) {
    const allowDrop = ref(null);
    const root = ref(null);
    const { context, resolver, allowed } = toRefs(props);

    let leaveTimer = null;
    let leaveEvent = null;

    const clearLeaveTimer = () => {
      clearTimeout(leaveTimer);
      leaveTimer = null;
      leaveEvent = null;
    };

    const resetLeaveTimer = (timeout) => {
      clearTimeout(leaveTimer);
      leaveTimer = setTimeout(() => {
        allowDrop.value = null;

        emit('drag-leave', leaveEvent);

        clearLeaveTimer();
      }, timeout);
    };

    const parse = (data) => {
      const obj = JSON.parse(data);
      return {
        ...obj,
        item: resolver.value(obj.item),
      };
    };

    return {
      allowDrop,
      root,

      handleDrop(ev) {
        ev.preventDefault();
        allowDrop.value = null;

        const transferItem = [...ev.dataTransfer.items].find((i) => i.type.startsWith('application/x-cymbal-'));
        const transferData = (transferItem) ? ev.dataTransfer.getData(transferItem.type) : null;
        const data = (transferData)
          ? parse(transferData)
          : { item: context.value.item.value };

        emit('drop', data?.item, ev);
        context.value.drop(root.value);
        clearLeaveTimer();
      },

      handleDragOver(ev) {
        const transferItem = [...ev.dataTransfer.items].find((i) => i.type.startsWith('application/x-cymbal-'));
        const transferData = (transferItem) ? ev.dataTransfer.getData(transferItem.type) : null;
        const data = (transferData)
          ? parse(transferData)
          : { item: context.value.item.value };
        // TODO: check content type of draggable
        if (allowed.value(data)) {
          ev.preventDefault();
          allowDrop.value = true;
          emit('drag-over', data?.item, ev);
          resetLeaveTimer(5000);
        }
      },

      handleDragLeave(ev) {
        ev.preventDefault();
        resetLeaveTimer(100);
        leaveEvent = ev;

      },
    };
  },
};
</script>
<style>
.drop-container {
  display: contents;
}
</style>
