<template>
  <th
      class="table-header"
      :colspan="columns || undefined"
      :title="helpText"
      :class="{
        [`table-header--${type}`]: !!type,
        'table-header--narrow': narrow,
        'table-header--help-text': !!helpText,
      }">
    <slot />
    <HelpIcon
        v-if="!!helpText"
        class="table-header__help" />
  </th>
</template>
<script>
import HelpIcon from '@carbon/icons-vue/es/help--filled/16.js';

export default {
  components: { HelpIcon },
  props: {
    type: {
      type: String,
      default: () => null,
    },
    narrow: {
      type: Boolean,
      default: () => false,
    },
    columns: {
      type: Number,
      default: () => null,
    },
    helpText: {
      type: String,
      default: () => null,
    },
  },
};
</script>
<style>
.table-header {
  padding: var(--dimension-small);

  font-size: .75rem;
  font-weight: 600;

  text-align: left;
  vertical-align: middle;

  & + & {
    border-left: var(--dimension-line) solid var(--color-background);
  }

  &--currency,
  &--rank,
  &--time {
    text-align: right;
    font-variant-numeric: tabular-nums;
  }

  &--center,
  &--integer {
    text-align: center;
  }

  &--integer, &--rank {
    width: 5ch;
  }

  &--time {
    width: 7ch;
  }

  &--right,
  &--end {
    text-align: right;
  }

  &--narrow {
    padding: var(--dimension-x-small);
  }

  &--help-text {
    cursor: help;

    white-space: nowrap;
  }

  &__help {
    display: inline-block;

    padding: var(--dimension-xx-small);

    vertical-align: middle;
  }
}
</style>
