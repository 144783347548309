<template>
  <Pane
      class="center-pane">
    <slot />
  </Pane>
</template>
<script>
import Pane from './Pane.vue';

export default {
  components: { Pane },
};
</script>
<style>
.center-pane {
  display: grid;
  justify-content: center;
  align-items: center;

  padding: var(--dimension-medium) var(--dimension-medium) var(--dimension-x-large);
}
</style>
