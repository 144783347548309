<template>
  <td
      class="table-cell"
      :colspan="columns || undefined"
      :class="{
        [`table-cell--${type}`]: !!type,
        'table-cell--narrow': narrow,
      }">
    <slot />
  </td>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: () => null,
    },
    narrow: {
      type: Boolean,
      default: () => false,
    },
    columns: {
      type: Number,
      default: () => null,
    },
  },
};
</script>
<style>
.table-cell {
  padding: var(--dimension-small);

  background: var(--table-current-row-background);

  text-align: left;
  vertical-align: middle;

  & + & {
    border-left: var(--dimension-line) solid color-mix(in srgb, var(--table-current-row-alternate-background), var(--table-current-row-background) 50%);
  }

  &--currency,
  &--rank,
  &--time {
    text-align: right;
    font-variant-numeric: tabular-nums;
  }

  &--number {
    font-variant-numeric: tabular-nums;
  }

  &--center,
  &--integer {
    text-align: center;
  }

  &--right,
  &--end {
    text-align: right;
  }

  &--narrow {
    padding: var(--dimension-x-small);
  }

  &--single-line {
    white-space: nowrap;
  }
}
</style>
